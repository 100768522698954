const dataAbout = [
    {
        id: 1,
        title: 'One Stop Event Solution Provider  ',
        desc: 'We work on building a relationship og trust with our clients creating a shared ambition that enables us to exceed our clients expectations.',
       
    },

]

export default dataAbout;